<div [class.login-page]="isLoginPage">
    @if (!isLoginPage) {
        <app-top-nav></app-top-nav>
    }

    @if (dotLoadingServices.isLoading()) {
        <app-dot-animation [isLoading]="isLoading()"></app-dot-animation>
    }
    @if (currentNotification(); as notification) {
        <app-notification-alert
                [type]="notification.type"
                [message]="notification.message">
        </app-notification-alert>
    }
    <router-outlet></router-outlet>

</div>