import {Component} from '@angular/core';
import { Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services";

@Component({
    selector: 'app-top-nav',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './top-nav.component.html',
    styleUrl: './top-nav.component.scss'
})
export class TopNavComponent {

    constructor(private router: Router, private authService: AuthService) {}

    isActive(route: string): boolean {
        return this.router.url.includes(route);
    }

    logOut() {
        this.authService.userSignOut();
    }
}
