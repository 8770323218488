import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from './components/services/notification.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DotLoadingServices} from "./core/services/dot-loading.Services";
import {initFlowbite} from "flowbite";
import {LoadingService} from "./core/components/loading/loading.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  isLoginPage: boolean = false;
  isOnline: boolean = navigator.onLine;

  isLoading = computed(() => this.dotLoadingServices.isLoading());
  currentNotification = computed(() => this.notificationService.notification());
  private subscriptions: Subscription = new Subscription();

  title = 'Maxim-ERP';

  constructor(
      private loadingService: LoadingService,
      public dotLoadingServices: DotLoadingServices,
      public notificationService: NotificationService,
      private router: Router
  ) {}

  ngOnInit() {
    initFlowbite();

    this.subscriptions.add(
        this.loadingService.isLoading.subscribe((isLoading) => {
          this.dotLoadingServices.setLoading(isLoading);
        })
    );

    this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isLoginPage = event.urlAfterRedirects.includes('/login');
    });

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    // Initial check
    this.updateOnlineStatus();
  }

  ngOnDestroy() {
    // Remove event listeners when component is destroyed
    window.removeEventListener('online', this.updateOnlineStatus.bind(this));
    window.removeEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  private updateOnlineStatus() {
    const wasOnline = this.isOnline;
    this.isOnline = navigator.onLine;

    if (this.isOnline && !wasOnline) {
      this.notificationService.showNotification({
        type: 'success',
        message: 'Connected. Internet Connection Restored.'
      });
    } else if (!this.isOnline && wasOnline) {
      this.notificationService.showNotification({
        type: 'error',
        message: 'Internet connection lost'
      });
    }
  }
}